// import React, { useState } from 'react';
// import './index.css';

// function App() {

//   return (
//     <div className="min-h-screen flex flex-col justify-center bg-gray-800">
      
//       <h1 className="text-4xl text-white text-center">Welcome to Somani Technologies.</h1>
//       <br />
//       <h3 className="text-2xl text-white text-center">We are currently in stealth mode and are working towards building a health tech stack for India and the world.</h3>
//       <br />
//       <h4 className="text-2xl text-white text-center">If you have any questions or want to join us on this adventure, please reach out!</h4>
//       <br />
//       <a href="mailto:info@somanitech.com" className="mx-auto block mt-8">
//       <button className="px-4 py-2 font-bold text-white bg-gradient-to-r from-blue-500 to-purple-500 rounded-full shadow-md hover:shadow-lg transition duration-500 ease-in-out">
//        Email Us
//      </button>
//       </a>
//     </div>
//   );
// }

// export default App;

// import React from 'react';
// import './index.css';

// function App() {

//   return (
//     <div className="min-h-screen flex flex-col justify-center bg-gradient-to-r from-blue-500 to-purple-500 text-white">
      
//       <h1 className="text-4xl text-center font-bold">Welcome to Somani Technologies.</h1>
//       <br />
//       <h3 className="text-2xl text-center">We are currently in stealth mode and are working towards building a health tech stack for India and the world.</h3>
//       <br />
//       <h4 className="text-2xl text-center">If you have any questions or want to join us on this adventure, please reach out!</h4>
//       <br />
//       <a href="mailto:info@somanitech.com" className="mx-auto block mt-8">
//         <button className="px-4 py-2 font-bold text-white bg-transparent border border-white hover:bg-white hover:text-blue-500 rounded-full shadow-md hover:shadow-lg transition duration-500 ease-in-out">
//           Email Us
//         </button>
//       </a>
//     </div>
//   );
// }

// export default App;


import React from 'react';
import './index.css';

function App() {
  return (
// <div className="min-h-screen flex flex-col justify-center bg-gradient-to-r from-blue-500 to-purple-500 animate-pulse text-white">
// <div className="min-h-screen flex flex-col justify-center pulse text-white"> from-orange-200 via-blue-500 to-green-300
<div className="min-h-screen flex flex-col justify-center bg-gradient-to-r from-orange-300 to-blue-300 pulse text-white">    
{/* // <div className="min-h-screen flex flex-col justify-center bg-gradient-to-b from-deep-ocean-teal via-shimmering-platinum to-rose-gold pulse text-white">
    // <div className="min-h-screen flex flex-col justify-center bg-gradient-grid from-deep-ocean-teal via-shimmering-platinum to-rose-gold pulse text-white"> */}

      <h1 className="text-4xl text-center font-bold">Welcome to Somani Technologies</h1>
      <br />
      <h3 style={{ padding: '16px 24px' }} className="text-xl text-center mt-14">We are currently in stealth mode and are working towards building a health tech stack for India and the world.</h3>
      <br />
      <h4 style={{ padding: '16px 24px' }} className="text-xl text-center mt-10">If you have any questions or want to join us on this adventure, please reach out!</h4>
      <br />
      <a href="mailto:info@somanitech.com" className="mx-auto block mt-8">
        <button className="px-4 py-2 font-bold text-white bg-transparent border border-white hover:bg-white hover:text-blue-500 rounded-full shadow-md hover:shadow-lg transition duration-500 ease-in-out">
          Email Us
        </button>
      </a>
    </div>
  );
}

export default App;